import '@around/scss/main.scss'
import './App.css';
import {Provider} from "react-redux";
import createSagaMiddleware from "redux-saga";
import {applyMiddleware, compose, createStore} from "redux";
import {createLogger} from "redux-logger";
import reducers from "@commons/domain/redux/reducers";
import rootSaga from "@commons/domain/redux/sagas";
import {BrowserRouter, Link, Navigate, Route, Routes, useParams} from "react-router-dom";
import {LANGUAGE_CODE_ENGLISH, LANGUAGE_CODE_HINDI_INDIA, LocalizedRouter} from "@commons/infra/i18n/LocalizedRouter";
import {setSharedStore} from "@commons/shared";
import AuthenticationChecker from "@commons/infra/AuthenticationChecker";
import {CustomRedirectSaver} from "@commons/ui/component/PostLoginRedirectHandler";
import FacebookPixel from "@commons/infra/component/FacebookPixel";
import {Slide, ToastContainer} from "react-toastify";
import React, {lazy} from "react";
import {ScrollToTop} from "@commons/infra/ScrollToTop";
import PublicHeader from "./public/component/PublicHeader";
import {postLoginRedirect} from "./infra/login";
import {AUTHORITY_SO_PATIENT} from "@commons/infra/consts";
import PublicFooter from "./public/component/PublicFooter";
import {CtaSectionWithArrow, FullHeightWrapper} from "@commons/ui/component/Section";
import {Container} from "react-bootstrap";
import {PAYMENT_METHOD__STRIPE} from "@commons/ui/page/appointment/PatientBookingPaymentPage";
import {PublicApi} from "./api/PublicApi";
import {PatientApi} from "./api/PatientApi";
import NotFoundPage from "@commons/ui/page/public/NotFoundPage";
import {MetaLinksHelmet} from "@commons/infra/i18n/MetaLinksHelmet";
import {PublicLinks} from "./links";
import {FormattedMessage} from "react-intl";
/**
 * Workaround to preload language and bypass eslint and IDEA warnings
 */
import English from '@commons/infra/i18n/translations/en.json';
import {activeHealthIssues} from "./public/health-issues";
import {activePreventionStrategies} from "./public/prevention-strategies";
import {externalLink} from "@commons/infra/helpers";
import {
    PosthogOpenFeedbackSurveyTrigger,
    PosthogPageviewTracker,
    PosthogUserIdentifier
} from "@commons/infra/thirdparty/posthog";
import {soAppointmentRequestReducer} from "./public/page/appointmentrequest/reducers";
import {setAppConfig} from "@commons/infra/appconfig";
import {defaultAppConfig} from "./config";
import {CommonPatientLinks} from "@commons/infra/links";
import LoadingPage from "@commons/uinew/component/loading/LoadingPage";
// eslint-disable-next-line
English.length


let LoginPage = lazy(() => import("@commons/ui/component/LoginPage"))
let Step1Page = lazy(() => import("./public/page/registration/RegistrationStep1"))
// let Step1ConfirmationPage = lazy(() => import("./public/page/registration/Step1ConfirmationPage"))
let Step2Page = lazy(() => import("./public/page/registration/RegistrationStep2"))
let PatientDashboardPage = lazy(() => import("./patient/page/PatientDashboardPage"))
let Restricted = lazy(() => import("@commons/ui/component/Restricted"))
let SingleDoctorPage = lazy(() => import("@commons/ui/page/public/SingleDoctorPage"))
let FaqPage = lazy(() => import("./public/page/FaqPage"))
let AppointmentReservationPage = lazy(() => import("@commons/ui/page/appointment/AppointmentReservationPage"))
let PatientBookingPaymentStatusPage = lazy(() => import("@commons/ui/page/appointment/PatientBookingPaymentStatusPage"))
let PatientBookingPatientInfoPage = lazy(() => import("@commons/ui/page/patient/booking/patientinfo/PatientBookingPatientInfoPage"))
let PatientBookingPageV2 = lazy(() => import("@commons/ui/page/patient/booking/PatientBookingPageV2"))
let PatientCallPage = lazy(() => import("@commons/ui/page/patient/CallPage"))
let SecondOpinionsPage = lazy(() => import("./public/page/SecondOpinionsPage"))
let PatientBookingsPage = lazy(() => import("@commons/ui/page/PatientBookingsPage"))
let HowItWorksPage = lazy(() => import("./public/page/HowItWorksPage").then(module => ({default: module.HowItWorksPage})))
let ContactsPage = lazy(() => import("./public/page/ContactsPage"))
let HealthIssuePage = lazy(() => import("@commons/ui/page/public/HealthIssuePage").then(module => ({default: module.HealthIssuePage})))
let HealthIssuesPage = lazy(() => import("@commons/ui/page/public/CardsPagePartial"))
let CameraMicHowTo = lazy(() => import("@commons/ui/page/public/CameraMicHowTo"))
let PasswordRecoveryPasswordChangePage = lazy(() => import("@commons/ui/component/PasswordRecoveryPasswordChangePage"))
let RequestPasswordRecoveryPage = lazy(() => import("@commons/ui/component/RequestPasswordRecoveryPage"))
let PatientProfilePage = lazy(() => import("@commons/ui/page/patient/PatientProfilePage"))
let PatientBookingPaymentPage = lazy(() => import("@commons/ui/page/appointment/PatientBookingPaymentPage"))
let Step1SelectDatesPage = lazy(() => import("./public/page/appointmentrequest/Step1SelectDatesPage"))
let PatientAppointmentRequestPage = lazy(() => import("./public/page/patient/PatientAppointmentRequestPage"))
let DoctorsLandingPage = lazy(() => import("./public/page/DoctorsLandingPage"))
let Step2ConfirmDatesPage = lazy(() => import("./public/page/appointmentrequest/Step2ConfirmDatesPage"))
let Step3RegisterEmailPage = lazy(() => import("./public/page/appointmentrequest/Step3RegisterEmailPage"))
let Step4RegisterEmailThankYouPage = lazy(() => import("./public/page/appointmentrequest/Step4ManualEmailVerificationPage"))
let Step5CompleteRegistration = lazy(() => import("./public/page/appointmentrequest/Step5CompleteRegistration"))
let Step6FinalThankYou = lazy(() => import("./public/page/appointmentrequest/Step6FinalThankYou"))
let HomePageV2 = lazy(() => import("./public/page/HomePageV2"))
let HomePageV3 = lazy(() => import("./public/page/HomePageV3"))
let HalfScreenOverlayLayout = lazy(() => import("@commons/ui/layout/HalfScreenOverlayLayout").then(module => ({default: module.HalfScreenOverlayLayout})))
let OnlyLogoPublicHeader = lazy(() => import("./public/component/OnlyLogoHeader"))
let AboutPage = lazy(() => import("./public/page/AboutPage"))
let PreventionPage = lazy(() => import("./public/page/PreventionPage"))
let Step1CalendarPage = lazy(() => import("./public/page/booking/Step1CalendarPage"))
let Step2ReviewPage = lazy(() => import("./public/page/booking/Step2ReviewPage"))
let BookingPaymentReservationPage = lazy(() => import("./public/page/booking/payment/BookingPaymentReservationPage"))
let BookingPaymentPaymentPage = lazy(() => import("./public/page/booking/payment/BookingPaymentPaymentPage"))
let BookingRegistrationStep3RegisterEmailPage = lazy(() => import("./public/page/booking/registration/BookingRegistrationStep3RegisterEmailPage"))
let BookingRegistrationStep4RegisterEmailThankYouPage = lazy(() => import("./public/page/booking/registration/BookingRegistrationStep4RegisterEmailThankYouPage"))
let BookingRegistrationStep5CompleteRegistration = lazy(() => import("./public/page/booking/registration/BookingRegistrationStep5CompleteRegistration"))
let LandingV1 = lazy(() => import("./public/page/landings/LandingV1"))
let LandingV2 = lazy(() => import("./public/page/landings/LandingV2"))
let LandingV3 = lazy(() => import("./public/page/landings/LandingV3"))
let LandingV4 = lazy(() => import("./public/page/landings/LandingV4"))
let LandingV5 = lazy(() => import("./public/page/landings/LandingV5"))

// Redux
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
    (state, action) => ({
        ...reducers(state, action),
        soAppointmentRequest: soAppointmentRequestReducer(state && state.soAppointmentRequest, action)
    }),
    composeEnhancers(applyMiddleware(sagaMiddleware, createLogger({})))
);
setSharedStore(store);
sagaMiddleware.run(rootSaga);

setAppConfig(defaultAppConfig)

export const PatientRestricted = (props) => {
    return <Restricted authority={AUTHORITY_SO_PATIENT} otherwiseRedirectTo='/login' content={props.children}/>
};

function App() {
    return <Provider store={store}>
        <AuthenticationChecker/>
        <LocalizedRouter RouterComponent={BrowserRouter}
                         languages={[LANGUAGE_CODE_ENGLISH, LANGUAGE_CODE_HINDI_INDIA]}
                         defaultLanguage={LANGUAGE_CODE_ENGLISH}
                         useEmptyLanguageNavigator={true}>
            {/*<CookieConsentModal/>*/}
            <MetaLinksHelmet alternateLanguages={[LANGUAGE_CODE_HINDI_INDIA]}
                             ignoredQueryParams={["rp"]}
                             defaultLanguageCode={LANGUAGE_CODE_ENGLISH}/>
            <CustomRedirectSaver/>
            <ScrollToTop/>
            {window.CONFIG.FACEBOOK_PIXEL.ENABLED && <FacebookPixel/>}
            <PosthogPageviewTracker/>
            <PosthogOpenFeedbackSurveyTrigger/>
            <ToastContainer
                autoClose={5000}
                closeOnClick={true}
                toastClassName={() => ""}
                transition={Slide}
                position={"top-center"}
                hideProgressBar={true}
                limit={1}
            />
            <PosthogUserIdentifier/>

            <React.Suspense fallback={<LoadingPage/>}>
                <Routes>
                    <Route path="/test-landings/1" element={<>
                        <FullHeightWrapper>
                            <LandingV1/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/test-landings/2" element={<>
                        <FullHeightWrapper>
                            <LandingV2/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/test-landings/3" element={<>
                        <FullHeightWrapper>
                            <LandingV3/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/test-landings/4" element={<>
                        <FullHeightWrapper>
                            <LandingV4/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/test-landings/5" element={<>
                        <FullHeightWrapper>
                            <LandingV5/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/v2" element={<>
                        <FullHeightWrapper>
                            <HomePageV3/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/v3" element={<>
                        <FullHeightWrapper>
                            <LandingV2/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/v4" element={<>
                        <FullHeightWrapper>
                            <LandingV3/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/v5" element={<>
                        <FullHeightWrapper>
                            <LandingV4/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/doctors" element={<>
                        <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
                        <FullHeightWrapper>
                            <DoctorsLandingPage/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/doctors/:doctorId" element={<>
                        <PublicHeader shadow={true}/>
                        <div className={"main-content d-flex flex-column"}>
                            <SingleDoctorPage getDoctorCall={PublicApi.getDoctor} appointmentRequestsEnabled={false}/>
                        </div>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/login" element={<>
                        <main className="page-wrapper d-flex flex-column">
                            <PublicHeader shadow={false}/>
                            <LoginPage postLoginRedirect={postLoginRedirect}/>
                        </main>
                        <PublicFooter/>

                    </>}/>

                    {/*<Route path="/registration" element={<>*/}
                    {/*    <PublicHeader shadow={false}/>*/}
                    {/*    <FullHeightWrapper>*/}
                    {/*        <Step1Page/>*/}
                    {/*    </FullHeightWrapper>*/}
                    {/*    <PublicFooter/>*/}
                    {/*</>}/>*/}

                    {/*<Route path="/registration/confirm-email" element={<>*/}
                    {/*    <PublicHeader shadow={false}/>*/}
                    {/*    <FullHeightWrapper>*/}
                    {/*        <Step1ConfirmationPage/>*/}
                    {/*    </FullHeightWrapper>*/}
                    {/*    <PublicFooter/>*/}
                    {/*</>}/>*/}

                    <Route path="/registration/continue" element={<>
                        <PublicHeader shadow={false}/>
                        <Step2Page/>
                    </>}/>

                    <Route path="/faq" element={<>
                        <PublicHeader shadow={false} bgClassName={"bg-secondary"}/>
                        <FullHeightWrapper className={"bg-secondary"}>
                            <FaqPage shadow={false}/>
                        </FullHeightWrapper>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/how-it-works" element={<>
                        <PublicHeader shadow={false}/>
                        <FullHeightWrapper>
                            <HowItWorksPage shadow={false}/>
                        </FullHeightWrapper>
                        <PublicFooter theme={'light'}/>
                    </>}/>

                    <Route path="/about-us" element={<>
                        <PublicHeader bgClassName={"bg-secondary"} shadow={false}/>
                        <FullHeightWrapper>
                            <AboutPage/>
                        </FullHeightWrapper>
                        <PublicFooter theme={'light'}/>
                    </>}/>

                    <Route path="/contacts" element={<>
                        <PublicHeader shadow={false}/>
                        <FullHeightWrapper>
                            <ContactsPage/>
                        </FullHeightWrapper>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/cardiology" element={<>
                        <Navigate to={PublicLinks.secondOpinions()} replace={true}/>
                    </>}/>
                    <Route path={PublicLinks.secondOpinions()} element={<>
                        <PublicHeader shadow={false}/>
                        <FullHeightWrapper>
                            <SecondOpinionsPage/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path={PublicLinks.prevention()} element={<>
                        <PublicHeader shadow={false}/>
                        <FullHeightWrapper>
                            <PreventionPage/>
                        </FullHeightWrapper>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/prevention-strategies" element={<>
                        <PublicHeader bgClassName={"bg-secondary"} shadow={false}/>
                        <main className="page-wrapper d-flex flex-column justify-content-between bg-secondary">
                            <HealthIssuesPage
                                itemsPrefix={"cms.prevention.so"}
                                prefix={"so_pages.prevention-strategies"}
                                linkFactory={(id) => PublicLinks.preventionStrategy(id)}
                                items={activePreventionStrategies}/>
                            <CtaSectionWithArrow
                                title={<FormattedMessage id={"pages.health-issues.cta.title"}/>}
                                text={<FormattedMessage id={"pages.health-issues.cta.text"}/>}
                                buttonLink={PublicLinks.bookAnAppointment.calendar()}
                                buttonText={<FormattedMessage id={"pages.doctor-specialities.cta.button"}/>}
                            />
                        </main>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/prevention/:healthIssueKey" element={<>
                        <PublicHeader shadow={false}/>
                        <FullHeightWrapper>
                            <HealthIssuePage
                                prefix={'cms.prevention.so'}
                                breadcrumbsRoot={{
                                    link: PublicLinks.preventionStrategies(),
                                    text: <FormattedMessage id={"so_components.public-header.areas-of-prevention"}/>
                                }}
                                showReviewedByEagle={true}
                                additionalContentMessageValues={{
                                    hopkinsMedicine1Link: externalLink("https://www.hopkinsmedicine.org/health/wellness-and-prevention/exercise-and-the-heart"),
                                    hopkinsMedicine2Link: externalLink("https://www.hopkinsmedicine.org/health/wellness-and-prevention/3-kinds-of-exercise-that-boost-heart-health"),
                                    link3: externalLink("https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"),
                                    link4: externalLink("https://www.hopkinsmedicine.org/health/wellness-and-prevention/exercise-and-the-heart"),
                                    link5: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6320919/"),
                                    link6: externalLink("https://www.bhf.org.uk/informationsupport/support/healthy-living/healthy-eating"),
                                    link7: externalLink("https://www.cdc.gov/heartdisease/risk_factors.htm"),
                                    link8: externalLink("https://www.nhs.uk/live-well/eat-well/food-types/salt-in-your-diet/"),
                                    link9: externalLink("https://www.health.harvard.edu/heart-health/the-sweet-danger-of-sugar"),
                                    link10: externalLink("https://www.bbcgoodfood.com/howto/guide/heart-healthy-portions"),
                                    link11: externalLink("https://www.healthline.com/health/heart-disease/work-with-your-doctor-to-prevent-heart-disease"),
                                    link12: externalLink("https://www.heart.org/en/health-topics/consumer-healthcare/what-is-cardiovascular-disease/heart-health-screenings"),
                                    link13: externalLink("https://www.bloodpressureuk.org/your-blood-pressure/how-to-lower-your-blood-pressure/monitoring-your-blood-pressure-at-home/how-to-measure-your-blood-pressure-at-home/"),
                                    link14: externalLink("https://www.healthline.com/health/heart-disease/work-with-your-doctor-to-prevent-heart-disease"),
                                    link15: externalLink("https://www.heartfoundation.org.au/bundles/your-heart/family-history-and-heart-disease"),
                                    link16: externalLink("https://www.health.harvard.edu/blog/lifestyle-changes-to-lower-heart-disease-risk-2019110218125"),
                                    link17: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6016051/"),
                                    link18: externalLink("https://www.cdc.gov/heartdisease/mentalhealth.htm"),
                                    link19: externalLink("https://www.helpguide.org/articles/stress/relaxation-techniques-for-stress-relief.htm"),
                                    link20: externalLink("https://www.health.harvard.edu/mind-and-mood/relaxation-techniques-breath-control-helps-quell-errant-stress-response"),
                                    link21: chunks => <Link
                                        to={PublicLinks.preventionStrategy("sleep-health")}>{chunks}</Link>,
                                    link22: externalLink("https://www.bannerhealth.com/healthcareblog/teach-me/how-sleep-can-affect-stress"),
                                    link23: externalLink("https://www.helpguide.org/articles/stress/social-support-for-stress-relief.htm"),
                                    link24: externalLink("https://www.bhf.org.uk/informationsupport/risk-factors/obesity"),
                                    link25: externalLink("https://www.health.harvard.edu/staying-healthy/6-simple-tips-to-reduce-your-blood-pressure"),
                                    link26: chunks => <Link
                                        to={PublicLinks.preventionStrategy("diet-and-nutrition")}>{chunks}</Link>,
                                    link27: chunks => <Link
                                        to={PublicLinks.preventionStrategy("physical-activities")}>{chunks}</Link>,
                                    link28: externalLink("https://www.cdc.gov/healthyweight/losing_weight/eating_habits.html"),
                                    link29: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6138918/"),
                                    link30: externalLink("https://www.fda.gov/tobacco-products/health-effects-tobacco-use/how-smoking-affects-heart-health"),
                                    link31: externalLink("https://www.nhs.uk/better-health/quit-smoking/benefits-of-quitting-smoking/"),
                                    link32: externalLink("https://www.betterhealth.vic.gov.au/health/healthyliving/What-to-expect-when-you-quit-smoking"),
                                    link33: externalLink("https://www.hopkinsmedicine.org/health/conditions-and-diseases/smoking-and-cardiovascular-disease"),
                                    link34: externalLink("https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2802963"),
                                    link35: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5513687/"),
                                    link36: externalLink("https://www.hopkinsmedicine.org/health/wellness-and-prevention/alcohol-and-heart-health-separating-fact-from-fiction"),
                                    link37: externalLink("https://www.nhs.uk/live-well/alcohol-advice/calculating-alcohol-units/"),
                                    link38: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5734879/"),
                                    link39: externalLink("https://www.cdc.gov/bloodpressure/sleep.htm#:~:text=During%20normal%20sleep%2C%20your%20blood,a%20longer%20period%20of%20time.&text=High%20blood%20pressure%20is%20one,adults%E2%80%94have%20high%20blood%20pressure"),
                                    link40: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7680518/"),
                                    link41: externalLink("https://sleepeducation.org/healthy-sleep/healthy-sleep-habits/"),
                                    link42: externalLink("https://www.heart.org/en/health-topics/sleep-disorders/sleep-and-heart-health#:~:text=Getting%20the%20right%20amount%20of,a%20healthy%20amount%20of%20sleep"),
                                    link43: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8531219/"),
                                    link44: externalLink("https://www.britishcardiovascularsociety.org/resources/editorials/articles/omega-3-fatty-acids-cardiovascular-risk-prevention"),
                                    link45: externalLink("https://bmcmedicine.biomedcentral.com/articles/10.1186/s12916-022-02328-x"),
                                    link46: externalLink("https://www.escardio.org/Education/ESC-Prevention-of-CVD-Programme/Treatment-goals/Cardio-Protective-drugs"),
                                    link47: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7006335/"),
                                    link48: externalLink("https://www.bhf.org.uk/informationsupport/risk-factors/family-history"),
                                    link49: externalLink("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7713504/"),
                                }}
                                ctaLink={PublicLinks.bookAnAppointment.calendar()}
                            />
                        </FullHeightWrapper>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/instructions/camera-mic-access" element={<>
                        <PublicHeader shadow={false}/>
                        <FullHeightWrapper>
                            <CameraMicHowTo/>
                        </FullHeightWrapper>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/health-issues" element={<>
                        <PublicHeader bgClassName={"bg-secondary"} shadow={false}/>
                        <main className="page-wrapper d-flex flex-column justify-content-between bg-secondary">
                            <HealthIssuesPage
                                itemsPrefix={"cms.health-issues.so"}
                                prefix={"so_pages.your-health"}
                                items={activeHealthIssues}
                                linkFactory={id => PublicLinks.healthIssue(id)}
                            />
                            <CtaSectionWithArrow
                                title={<FormattedMessage id={"pages.health-issues.cta.title"}/>}
                                text={<FormattedMessage id={"pages.health-issues.cta.text"}/>}
                                buttonLink={PublicLinks.bookAnAppointment.calendar()}
                                buttonText={<FormattedMessage id={"pages.doctor-specialities.cta.button"}/>}
                            />
                        </main>
                        <PublicFooter theme={"light"}/>
                    </>}/>

                    <Route path="/health-issues/:healthIssueKey" element={<>
                        <PublicHeader shadow={false}/>
                        <FullHeightWrapper>
                            <HealthIssuePage
                                prefix={'cms.health-issues.so'}
                                showReviewedByEagle={true}
                                ctaLink={PublicLinks.bookAnAppointment.calendar()}
                            />
                        </FullHeightWrapper>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/password-recovery/change-password" element={<>
                        <main className="page-wrapper d-flex flex-column">
                            <PublicHeader shadow={false}/>
                            <PasswordRecoveryPasswordChangePage/>
                        </main>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/password-recovery" element={<>
                        <main className="page-wrapper d-flex flex-column">
                            <PublicHeader shadow={false}/>
                            <RequestPasswordRecoveryPage/>
                        </main>
                        <PublicFooter/>
                    </>}/>

                    {/**
                     * Mostly patient pages
                     */}
                    <Route path="/my/dashboard" element={
                        <PatientRestricted>
                            <main className="page-wrapper d-flex flex-column">
                                <PublicHeader shadow={false}/>
                                <div className={"main-content"}>
                                    <PatientDashboardPage/>
                                </div>
                            </main>
                            <PublicFooter/>
                        </PatientRestricted>
                    }/>

                    <Route path="/my/bookings" element={
                        <PatientRestricted>
                            <PublicHeader/>
                            <Container className={"main-content"}>
                                <PatientBookingsPage/>
                            </Container>
                            <PublicFooter/>
                        </PatientRestricted>
                    }/>

                    <Route path="/my/profile/*" element={
                        <PatientRestricted>
                            <PublicHeader shadow={false} bgClassName={"bg-faded-primary-solid"}/>
                            <div className={"main-content bg-faded-primary"}>
                                <PatientProfilePage showSurveys={false}/>
                            </div>
                            <PublicFooter/>
                        </PatientRestricted>
                    }/>

                    <Route path="/doctors/:doctorId/bookings/:bookingId/payment" element={<>
                        <PatientRestricted>
                            <PublicHeader shadow={true}/>
                            <Container className={"main-content"}>
                                <PatientBookingPaymentPage
                                    paymentMethods={[PAYMENT_METHOD__STRIPE]}
                                    initiateStripeCheckoutSession={PatientApi.initiateStripeCheckoutSession}/>
                            </Container>
                            <PublicFooter variant={'dark'}/>
                        </PatientRestricted>
                    </>}/>

                    <Route path="/doctors/:doctorId/appointments/:appointmentId" element={<>
                        <PatientRestricted>
                            <PublicHeader shadow={true}/>
                            <Container className={"main-content"}>
                                <AppointmentReservationPage
                                    getAppointmentCall={PatientApi.getAppointment}
                                    reserveAppointmentCall={PatientApi.reserveAppointment}
                                    showAppointmentWillHappenOnlineWarning={false}
                                />
                            </Container>
                            <PublicFooter/>
                        </PatientRestricted>
                    </>}/>

                    <Route path="/book-an-appointment/registration/email" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <FullHeightWrapper>
                            <BookingRegistrationStep3RegisterEmailPage/>
                        </FullHeightWrapper>
                    </>}/>

                    <Route path="/book-an-appointment/registration/email-confirmation" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <FullHeightWrapper>
                            <BookingRegistrationStep4RegisterEmailThankYouPage/>
                        </FullHeightWrapper>
                    </>}/>

                    <Route path="/book-an-appointment/registration/finalization" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <FullHeightWrapper>
                            <BookingRegistrationStep5CompleteRegistration/>
                        </FullHeightWrapper>
                    </>}/>

                    {/* Reservation */}
                    <Route path="/book-an-appointment/reservation/:appointmentId" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>

                        <BookingPaymentReservationPage
                            getAppointmentCall={PatientApi.getAppointment}
                            reserveAppointmentCall={PatientApi.reserveAppointment}
                            showAppointmentWillHappenOnlineWarning={false}
                        />
                    </>}/>

                    {/* Payment */}
                    <Route path="/book-an-appointment/payment/:bookingId" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>

                        <BookingPaymentPaymentPage
                            paymentMethods={[PAYMENT_METHOD__STRIPE]}
                            initiateStripeCheckoutSession={PatientApi.initiateStripeCheckoutSession}
                        />
                    </>}/>

                    <Route path="/book-an-appointment" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <Step1CalendarPage/>
                    </>}/>

                    <Route path="/book-an-appointment/:appointmentId" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <Step2ReviewPage/>
                    </>}/>

                    <Route path="/request-appointment" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        {/*<FullHeightWrapper className={"z-2"}>*/}
                        <Step1SelectDatesPage/>
                        {/*</FullHeightWrapper>*/}
                    </>}/>

                    <Route path="/request-appointment/confirm-dates" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <FullHeightWrapper>
                            <Step2ConfirmDatesPage/>
                        </FullHeightWrapper>
                    </>}/>

                    <Route path="/request-appointment/registration/email" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <FullHeightWrapper>
                            <Step3RegisterEmailPage/>
                        </FullHeightWrapper>
                    </>}/>

                    <Route path="/request-appointment/registration/email-confirmation" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <FullHeightWrapper>
                            <Step4RegisterEmailThankYouPage/>
                        </FullHeightWrapper>
                    </>}/>

                    <Route path="/request-appointment/registration/finalization" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <FullHeightWrapper>
                            <Step5CompleteRegistration/>
                        </FullHeightWrapper>
                    </>}/>

                    <Route path="/request-appointment/completed" element={<>
                        <OnlyLogoPublicHeader shadow={false} sticky={false} bgClassName={""}/>
                        <HalfScreenOverlayLayout/>
                        <FullHeightWrapper>
                            <Step6FinalThankYou/>
                        </FullHeightWrapper>
                    </>}/>

                    <Route path="/my/appointment-request" element={<>
                        <PublicHeader shadow={false}/>
                        <FullHeightWrapper>
                            <PatientAppointmentRequestPage/>
                        </FullHeightWrapper>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/doctors/:doctorId/bookings/:bookingId/payment/status" element={<>
                        <PatientRestricted>
                            <PublicHeader shadow={true}/>
                            <Container className={"main-content"}>
                                <PatientBookingPaymentStatusPageWrapper/>
                            </Container>
                            <PublicFooter/>
                        </PatientRestricted>
                    </>}/>

                    <Route path="/my/bookings/:bookingId/patient-info" element={<>
                        <PatientRestricted>
                            <PublicHeader shadow={true}/>
                            <Container className={"main-content"}>
                                <PatientBookingPatientInfoPage allowDifferentActualPatient={true}/>
                            </Container>
                            <PublicFooter/>
                        </PatientRestricted>
                    </>}/>

                    <Route path="/my/bookings/:bookingId" element={
                        <PatientRestricted>
                            <PublicHeader shadow={false}/>
                            <PatientBookingPageV2/>
                            <PublicFooter/>
                        </PatientRestricted>
                    }/>


                    <Route path="/my/bookings/:bookingId/call" element={
                        <PatientRestricted>
                            <PatientCallPage requireAuthentication={false}/>
                        </PatientRestricted>
                    }/>

                    {/**
                     * Home
                     */}

                    <Route path="/" exact element={<>
                        <main className="page-wrapper">
                            <HomePageV3/>
                        </main>
                        <PublicFooter theme={'light'}/>
                    </>}/>

                    <Route path="/home-v2" exact element={<>
                        <main className="page-wrapper">
                            <HomePageV2/>
                        </main>
                        <PublicFooter/>
                    </>}/>

                    <Route path="/home-v3" exact element={<>
                        <main className="page-wrapper">
                            <HomePageV3/>
                        </main>
                        <PublicFooter theme={'light'}/>
                    </>}/>

                    <Route path="*" strict status={404} element={<>
                        <PublicHeader/>
                        <Container className={"main-content"}>
                            <NotFoundPage/>
                        </Container>
                        <PublicFooter/>
                    </>}/>
                </Routes>

            </React.Suspense>
        </LocalizedRouter>
    </Provider>
}


let PatientBookingPaymentStatusPageWrapper = () => {
    let {bookingId} = useParams()

    return <PatientBookingPaymentStatusPage
        successRedirectLink={CommonPatientLinks.booking.patientInfo(bookingId)}
        failureRedirectLink={PublicLinks.bookAnAppointment.payment.payment(bookingId)}
    />
}

export default App;
