import {LANGUAGE_CODE_ENGLISH} from "@commons/infra/i18n/LocalizedRouter";

export let defaultAppConfig = {
    i18n: {
        languages: [LANGUAGE_CODE_ENGLISH],
        defaultLanguage: LANGUAGE_CODE_ENGLISH,
    },
    appointment: {
        price: {
            eurFormatted: "€55",
            inrFormatted: "₹4,900",
            discount: {
                active: true,
                eurFormatted: "€75",
                inrFormatted: "₹6,700",
            }
        },
    },
    patient: { // duplicates in env config
        appointment: {
            showTimeToStartCallBefore: 60 // minutes
        }
    },
}