import React from 'react';
import Logo from "@commons/ui/component/Logo";
import {getSoPrivacyPolicyUrl, soTermsAndConditionsUrl} from "@commons/infra/helpers";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {FormattedMessage} from "react-intl";
import {CommonPublicLinks} from "@commons/infra/links";
import {PublicLinks} from "../../links";

export let copyrightText = <FormattedMessage id={"common.copyright"} values={{
    copy: <>&copy;</>, year: new Date().getFullYear()
}}/>;

const PublicFooter = ({theme = 'dark'}) => {
    let breakpoint = 'md'

    // let logoClass = theme === 'light' ? 'text-dark ' : ''
    // let backgroundClass = theme === 'light' ? 'bg-secondary' : 'bg-dark'
    // let textClass = theme === 'light' ? '' : 'text-light'

    // let logoClass = ''
    let backgroundClass = ''
    let textClass = ''

    return <>
        <footer className={`footer footer-${theme} ${backgroundClass} py-3 py-${breakpoint}-4 pt-md-5 z-2`}>
            <div className="container pt-md-0">
                <div className="row">

                    <div className="col-md-4 col-lg-3 pb-2 pb-md-0 mb-4 mb-md-0 text-center text-md-start">
                        <LocalizedLink className="d-block mb-3 mx-auto mx-md-0 text-decoration-none fs-xl"
                                       to={PublicLinks.home()}>
                            <Logo className={`logo me-0`}/>
                        </LocalizedLink>

                        <div className={`fs-sm pb-2 pb-sm-3`}>
                            <div className="mb-1 d-flex justify-content-center justify-content-md-start">
                                <i className={`d-none d-${breakpoint}-block ai-mail fs-lg me-2`}/>
                                <div>info@medon.lv</div>
                            </div>
                            <div className="mb-1 d-flex justify-content-center justify-content-md-start">
                                <i className={`d-none d-${breakpoint}-block ai-phone fs-lg me-2`}/>
                                <div>+371 25 645 645</div>
                            </div>
                        </div>

                        <SocialButton theme={theme} social={"facebook"} link={"https://facebook.com/medon.health.io"}/>
                        <SocialButton theme={theme} social={"instagram"}
                                      link={"https://www.instagram.com/medon.health/"}/>
                        <SocialButton theme={theme} social={"linkedin"}
                                      link={"https://www.linkedin.com/company/medon-health"}/>
                    </div>

                    <div className={"col-md-8 col-lg-8 offset-lg-1 col-xl-7 offset-xl-2"}>
                        <div className="row row-cols-1 row-cols-sm-2">
                            <div className="col mb-4 mb-md-0 text-center text-md-start">
                                <h6 className={`${textClass} mb-3`}>
                                    <FormattedMessage id={"components.public-footer.information"}/>
                                </h6>
                                <div className={`fs-sm ${textClass} pb-2 pb-sm-3`}>
                                    <div className="mb-1">
                                        <LocalizedLink to={PublicLinks.secondOpinions()}>
                                            <FormattedMessage id={"so_header.second-opinions"}/>
                                        </LocalizedLink>
                                    </div>
                                    <div className="mb-1">
                                        <LocalizedLink to={PublicLinks.prevention()}>
                                            <FormattedMessage id={"so_header.prevention"}/>
                                        </LocalizedLink>
                                    </div>
                                    {/*<div className="mb-1">*/}
                                    {/*    <LocalizedLink to={PublicLinks.home()}>*/}
                                    {/*        <FormattedMessage id={"so_components.public-footer.600"}*/}
                                    {/*                          defaultMessage={"Heart health assessment TODO"}/>*/}
                                    {/*    </LocalizedLink>*/}
                                    {/*</div>*/}
                                    <div className="mb-1">
                                        <LocalizedLink to={CommonPublicLinks.contacts()}>
                                            <FormattedMessage id={"components.public-footer.contacts"}/>
                                        </LocalizedLink>
                                    </div>
                                    <div className="mb-1">
                                        <LocalizedLink to={CommonPublicLinks.aboutUs()}>
                                            <FormattedMessage id={"components.public-header.about-us"}/>
                                        </LocalizedLink>
                                    </div>
                                    <div className="mb-1">
                                        <a href={soTermsAndConditionsUrl()} target={"_blank"}
                                           rel="noopener noreferrer" className={"hide-external-icon"}>
                                            <FormattedMessage id={"components.public-footer.terms-and-conditions"}/>
                                        </a>
                                    </div>
                                    <div className="mb-1">
                                        <a href={getSoPrivacyPolicyUrl()} target={"_blank"}
                                           rel="noopener noreferrer" className={"hide-external-icon"}>
                                            <FormattedMessage id={"components.public-footer.privacy-policy"}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col mb-4 mb-md-0 text-center text-md-start">
                                <h6 className='mb-3'>
                                    <FormattedMessage id={"so_components.public-footer.500"}
                                                      defaultMessage={"Cardiovascular diseases"}/>
                                </h6>
                                <div className={"fs-sm text-light pb-2 pb-sm-3"}>
                                    <div className="mb-1">
                                        <HealthIssueLink id={"coronary-artery-disease"}/>
                                    </div>
                                    <div className="mb-1">
                                        <HealthIssueLink id={"hypertension"}/>
                                    </div>
                                    <div className="mb-1">
                                        <HealthIssueLink id={"increased-cholesterol"}/>
                                    </div>
                                    <div className="mb-1">
                                        <HealthIssueLink id={"arrhythmias"}/>
                                    </div>
                                    <div className="mb-1">
                                        <HealthIssueLink id={"atherosclerosis"}/>
                                    </div>
                                    <div className="mb-1">
                                        <HealthIssueLink id={"heart-failure"}/>
                                    </div>
                                    <div className="mb-1">
                                        <HealthIssueLink id={"congenital-heart-defects"}/>
                                    </div>
                                    <div className="mb-1">
                                        <HealthIssueLink id={"valvular-heart-diseases"}/>
                                    </div>
                                    <LocalizedLink to={CommonPublicLinks.healthIssues()}>
                                        <FormattedMessage id={`so_common.other-heart-conditions`}/>
                                    </LocalizedLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row mt-1 py-1 py-${breakpoint}-4 text-center text-${breakpoint}-start`}>
                    <div className="col-12">
                        <div className={"mb-2"}>
                            <div className="fs-sm mb-2">
                                <FormattedMessage id={"components.public-footer.payment-methods-we-accept"}/>
                            </div>
                            <div className={"d-flex gap-2 justify-content-center justify-content-md-start"}>
                                <div className={"credit-card-icon visa"}/>
                                <div className={"credit-card-icon mastercard"}/>
                                <div className={"credit-card-icon amex"}/>
                                <div className={"credit-card-icon jcb"}/>
                                <div className={"credit-card-icon diners"}/>
                                <div className={"credit-card-icon discover"}/>
                            </div>
                        </div>
                        <hr className={"opacity-25"}/>
                        <p className="fs-sm mt-2 mb-0">
                            {copyrightText}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </>
};

let HealthIssueLink = ({id}) => {
    return <LocalizedLink to={CommonPublicLinks.healthIssue(id)}>
        <FormattedMessage id={`so_common.disease.${id}`}/>
    </LocalizedLink>
}

let SocialButton = ({theme = 'dark', social, link}) => {
    let buttonClass = theme === 'dark' ? 'btn-secondary' : 'btn-dark';
    let iconClass = theme === 'dark' ? '' : 'text-light';
    return <a
        className={`social-button btn btn-icon btn-sm ${buttonClass} rounded-circle me-2 mb-2 btn-${social} bs-lg hide-external-icon`}
        href={link}
        target={"_blank"}
        rel="noopener noreferrer">

        <i className={`ai-${social} ${iconClass}`}/>
    </a>
}

export default PublicFooter;
