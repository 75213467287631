import {backendUrl} from "@commons/infra/helpers";

let CommonRegistrationLinks = {
    registerEmail: (prefix, params = {}) => () => {
        let url = new URL(`${prefix}/registration/email`, window.CONFIG.FRONTEND_BASE_URL);

        Object.entries(params).forEach(([name, value]) => {
            url.searchParams.append(name, value);
        });

        return url.toString().replace(window.CONFIG.FRONTEND_BASE_URL, '')
    },
    registerEmailThankYou: (prefix) => (email) => `${prefix}/registration/email-confirmation?email=${encodeURIComponent(email)}`,
    completeRegistration: (prefix) => (emailVerificationType, code, sid) => `${prefix}/registration/finalization?email_verification_type=${emailVerificationType}&code=${code}&sid=${sid}`,
}

export let PublicLinks = {
    home: () => "/",
    signIn: () => "/login",
    registration: {
        step1: () => "/registration",
        step1confirm: (email) => `/registration/confirm-email?email=${email}`,
    },
    doctors: () => "/doctors",
    doctor: (slug) => `/doctors/${slug}`,
    about: () => "/about-us",
    faq: (anchor) => `/faq${anchor ? `#${anchor}` : ''}`,
    micAndCameraInstructions: () => "/instructions/camera-mic-access",
    contacts: () => "/contacts",
    howItWorks: () => `/how-it-works`,
    secondOpinions: () => `/second-opinions`,
    prevention: () => `/prevention`,
    preventionStrategy: (id) => `/prevention/${id}`,
    preventionStrategies: () => `/prevention-strategies`,
    healthIssue: (healthIssueId) => `/health-issues/${healthIssueId}`,
    healthIssues: () => `/health-issues`,
    appointmentRequest: {
        form: () => `/request-appointment`,
        confirm: () => `/request-appointment/confirm-dates`,
        registerEmail: CommonRegistrationLinks.registerEmail('/request-appointment'),
        registerEmailThankYou: CommonRegistrationLinks.registerEmailThankYou('/request-appointment'),
        completeRegistration: CommonRegistrationLinks.completeRegistration('/request-appointment'),
        completed: () => `/request-appointment/completed`
    },
    bookAnAppointment: {
        calendar: () => `/book-an-appointment`,
        review: (appointmentId) => `/book-an-appointment/${appointmentId}`,
        registration: {
            registerEmail: (appointmentId) => CommonRegistrationLinks.registerEmail('/book-an-appointment', {appointmentId: appointmentId})(),
            registerEmailThankYou: CommonRegistrationLinks.registerEmailThankYou('/book-an-appointment'),
            completeRegistration: CommonRegistrationLinks.completeRegistration('/book-an-appointment'),
        },
        // todo dis is not public
        payment: {
            reservation: (appointmentId) => `/book-an-appointment/reservation/${appointmentId}`,
            payment: (bookingId) => `/book-an-appointment/payment/${bookingId}`,
        },
    }
}

export let PatientLinks = {
    dashboard: () => '/my/dashboard', profile: {
        info: () => '/my/profile/info', surveys: () => '/my/profile/surveys'
    }, reservation: {
        reservation: (doctorId, appointmentId) => `/doctors/${doctorId}/appointments/${appointmentId}`,
        payment: (doctorId, bookingId) => `/doctors/${doctorId}/bookings/${bookingId}/payment`,
        paymentStatus: (doctorId, bookingId) => `/doctors/${doctorId}/bookings/${bookingId}/payment/status`,
    }, authentication: {
        status: (authenticationId) => `/my/authentication/${authenticationId}/status`
    }, booking: {
        list: () => '/my/bookings',
        summary: (bookingId) => `/my/bookings/${bookingId}`,
        patientInfo: (bookingId) => `/my/bookings/${bookingId}/patient-info`,
        authentication: (bookingId) => `/my/bookings/${bookingId}/authentication`,
        call: (bookingId) => `/my/bookings/${bookingId}/call`,
        callPlatformSelection: (bookingId) => `/my/bookings/${bookingId}/platform-selection`,
        joinCallOnMobile: (bookingId) => `/my/bookings/${bookingId}/platform-selection/mobile-qr`,
        callInternal: (bookingId) => `/my/bookings/${bookingId}/call`
    }, appointment: {
        patientDocument: (appointmentId, documentId) => backendUrl(`/api/patient/booking/${appointmentId}/document/${documentId}`)
    }, appointmentRequest: {
        form: (doctorId) => `/doctors/${doctorId}/request-appointment`,
        confirm: (doctorId) => `/doctors/${doctorId}/request-appointment/confirm`,
        summary: (appointmentRequestId) => `/my/appointment-requests/${appointmentRequestId}`,
        info: () => `/my/appointment-request`,
    }, doctor: {
        doctor: (doctorId) => `/doctors/${doctorId}`,
        requestAppointment: (doctorId) => `/doctors/${doctorId}/request-appointment`,
    },
}