export let activePreventionStrategies = [
    {key: 'physical-activities'},
    {key: 'diet-and-nutrition'},
    {key: 'medical-tests-and-screenings'},
    {key: 'mental-health-and-stress'},
    {key: 'weight-management'},
    {key: 'smoking-cessation'},
    {key: 'alcohol-consumption'},
    {key: 'sleep-health'},
    {key: 'preventive-medication-and-supplements'},
    {key: 'family-history-and-genetics'},
]