import {Helmet} from "react-helmet";
import React from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {title} from "@commons/shared";
import {FormattedMessage, useIntl} from "react-intl";
import {PublicLinks} from "../../../../../portal-latvia/src/utility/links";

let NotFoundPage = () => {
    let {formatMessage} = useIntl();

    return <>
        <Helmet>
            <title>{title(formatMessage({id: 'pages.404.page-title'}))}</title>
            <meta name="robots" content="noindex"/>
        </Helmet>

        <div className="row pt-4 pb-7">
            <div className={"offset-md-3 col-md-6 mt-6 text-center"}>
                <h2>
                    <FormattedMessage id={"pages.404.title"} />
                </h2>
                <p>
                    <FormattedMessage id={"pages.404.text"} />
                </p>
                <LocalizedLink className={"btn btn-pill btn-primary px-4"} to={PublicLinks.home()}>
                    <FormattedMessage id={"pages.404.button"} />
                </LocalizedLink>
            </div>
        </div>
    </>
}

export default NotFoundPage;
