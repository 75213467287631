import axios from "@commons/infra/http/axios/axios";
import {defaultScheduleSizeInDays} from "@commons/api/public/DoctorService";

export let PublicApi = {
    getDoctors: ({search, speciality}) => {
        let url = new URL(`/api/so/doctors`, axios.defaults.baseURL);

        if (search) {
            url.searchParams.append('name', search);
        }
        speciality.forEach(s => {
            url.searchParams.append('specialities', s);
        });

        return axios.get(url.href)
    },

    getDoctor: ({doctorId, scheduleFrom, scheduleSizeInDays = defaultScheduleSizeInDays}) => {
        let scheduleTo = scheduleFrom.plus({days: scheduleSizeInDays});

        return axios.get(`/api/so/doctors/${doctorId}?scheduleFrom=${scheduleFrom.toUTC()}&scheduleTo=${scheduleTo.toUTC()}`)
    },

    getUnifiedCalendar: (from, to) => {
        return axios.get(`/api/so/unified-calendar?from=${from.toUTC()}&to=${to.toUTC()}`)
    }
}