import React, {useEffect, useState} from "react";
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {AppointmentsApi} from "../../../api/patient/AppointmentApi";
import {unexpectedErrorOccurred} from "@commons/infra/helpers";
import Loading from "@commons/ui/component/Loading";
import {
    BANKLINK__TARGET_BANK__CITADELE,
    BANKLINK__TARGET_BANK__SEB,
    BANKLINK__TARGET_BANK__SWEDBANK,
    PatientAppointmentsApi
} from "../../../api/patient/PatientAppointmentApi";
import {AppointmentOverviewV3} from "./AppointmentReservationPage";
import {toast} from "react-toastify";
import {CommonPublicLinks} from "@commons/infra/links";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {FormattedMessage, useIntl} from "react-intl";
import {InfoToast} from "@commons/infra/component/Toast";
import {clearOngoingReservations, fetchOngoingReservations} from "@commons/domain/redux/actions";
import {connect} from "react-redux";

import {FormBackButton, FormButtonsContainer, FormProceedButton} from "@commons/uinew/component/forms";
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";
import {PublicLinks} from "../../../../../portal-latvia/src/utility/links";
import RadioWithImage from "@commons/uinew/component/buttons/RadioButton1";

export const PAYMENT_METHOD__SWEDBANK = 'swedbank';
export const PAYMENT_METHOD__SEB = 'seb';
export const PAYMENT_METHOD__CITADELE = 'citadele';
export const PAYMENT_METHOD__STRIPE = 'stripe';

// Is being re-used
let PatientBookingPaymentPage = ({
                                     showBreadcrumbs,
                                     patientOngoingReservation,
                                     fetchOngoingReservations,
                                     cancelLinkFactory,
                                     clearOngoingReservations,
                                     priceFormatted,
                                     paymentMethods = [PAYMENT_METHOD__STRIPE, PAYMENT_METHOD__SWEDBANK, PAYMENT_METHOD__CITADELE, PAYMENT_METHOD__SEB],
                                     initiateStripeCheckoutSession
                                 }) => {
    let {formatMessage} = useIntl();
    let localizedRoutes = useLocalizedRoutes()
    let {doctorId, bookingId} = useParams();

    let [isLoading, setIsLoading] = useState(false);
    let [booking, setBooking] = useState();
    let [redirect, setRedirect] = useState()
    let [method, setMethod] = useState(paymentMethods.includes(PAYMENT_METHOD__SWEDBANK) ? PAYMENT_METHOD__SWEDBANK : paymentMethods[0])
    let navigate = useNavigate();
    let [isInitialized, setIsInitialized] = useState(false)

    useEffect(() => {
        if (!isInitialized || patientOngoingReservation.isLoading)
            return;

        if (!patientOngoingReservation.exists || patientOngoingReservation.appointment.bookingId !== bookingId) {
            // User is not allowed here
            navigate(CommonPublicLinks.doctor(doctorId))
        }
    }, [isInitialized, navigate, patientOngoingReservation, doctorId, bookingId])

    useEffect(() => {
        AppointmentsApi.getBooking(bookingId)
            .then(r => r.data)
            .then(d => setBooking(d))

        fetchOngoingReservations();
        setIsInitialized(true)
    }, [bookingId, fetchOngoingReservations]);

    let initiateSwedbankPayment = (bookId, targetBank) => {
        setIsLoading(true);
        PatientAppointmentsApi.initiateSwedbankPayment(bookId, targetBank)
            .then(r => r.data)
            .then(d => window.location.href = d.redirectUrl)
            .finally(() => setIsLoading(false))
    }

    let onCancel = (e) => {
        e.preventDefault();
        PatientAppointmentsApi
            .cancelAppointmentReservation(bookingId)
            .finally(() => {
                clearOngoingReservations()
                toast(<InfoToast text={formatMessage({id: 'pages.booking-payment.toast.cancelled-successfully'})}/>)
                setRedirect(cancelLinkFactory(booking.doctor.slug))
            })
    }

    let onStart = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        switch (method) {
            case PAYMENT_METHOD__SWEDBANK:
                initiateSwedbankPayment(bookingId, BANKLINK__TARGET_BANK__SWEDBANK)
                break;

            case PAYMENT_METHOD__SEB:
                initiateSwedbankPayment(bookingId, BANKLINK__TARGET_BANK__SEB)
                break;

            case PAYMENT_METHOD__CITADELE:
                initiateSwedbankPayment(bookingId, BANKLINK__TARGET_BANK__CITADELE)
                break;

            case PAYMENT_METHOD__STRIPE:
                import('@stripe/stripe-js').then(module => {
                    module
                        .loadStripe(window.CONFIG.STRIPE.PUBLIC_KEY)
                        .then(stripe => {
                            initiateStripeCheckoutSession(bookingId)
                                .then(r => r.data)
                                .then(async (d) => {
                                    // When the customer clicks on the button, redirect them to Checkout.
                                    const result = await stripe.redirectToCheckout({
                                        sessionId: d.sessionId,
                                    });
                                    if (result.error) {
                                        unexpectedErrorOccurred(result.error.message)
                                        // If `redirectToCheckout` fails due to a browser or network
                                        // error, display the localized error message to your customer
                                        // using `result.error.message`.
                                    }
                                })
                                .finally(() => setIsLoading(false))
                        });
                })

                break;

            default:
                return;
        }
    }

    // eslint-disable-next-line no-undef
    return <>
        {redirect && <Navigate to={localizedRoutes.unpack(redirect)} replace={true}/>}

        {booking && <>
            {showBreadcrumbs && <nav aria-label="breadcrumb">
                <ol className="py-1 my-2 breadcrumb">
                    <li className="breadcrumb-item">
                        <LocalizedLink to={PublicLinks.doctors()}>
                            <FormattedMessage id={'components.public-header.specialists'}/>
                        </LocalizedLink>
                    </li>
                    <li className="breadcrumb-item">
                        <LocalizedLink to={PublicLinks.doctor(booking.doctor.slug)}>
                            {booking.doctor.fullName}
                        </LocalizedLink>
                    </li>
                </ol>
            </nav>}

            <h1 className="mb-3">
                <FormattedMessage id={'pages.booking-payment.heading'}/>
            </h1>

            {patientOngoingReservation.isLoading && <Loading/>}

            {!patientOngoingReservation.isLoading && patientOngoingReservation.exists && <>

                <AppointmentOverviewV3
                    from={booking.from}
                    to={booking.to}
                    price={priceFormatted || booking.price}
                />

                <p>
                    <FormattedMessage
                        id={'pages.booking-payment.text-1'}
                        values={{
                            ul: chunk => <ul className={"mt-2"}>{chunk}</ul>,
                            li: chunk => <li>{chunk}</li>
                        }}/>
                </p>

                <h6 className={"mt-2"}>
                    <FormattedMessage id={'pages.booking-payment.choose-payment-method'}/>
                </h6>

                <div className={"row row-cols-1 row-cols-md-4 g-3 mb-5"}>
                    {paymentMethods.includes(PAYMENT_METHOD__SWEDBANK) && <div className={"col"}>
                        <RadioWithImage idPrefix={"appointment-payment-"}
                                        image={"/img/payment/swedbank.gif"}
                                        value={PAYMENT_METHOD__SWEDBANK}
                                        method={method}
                                        setMethod={setMethod}
                                        imgStyle={{marginLeft: '5px'}}/>
                    </div>}

                    {paymentMethods.includes(PAYMENT_METHOD__SEB) && <div className={"col"}>
                        <RadioWithImage idPrefix={"appointment-payment-"}
                                        image={"/img/payment/seb.jpg"}
                                        value={PAYMENT_METHOD__SEB}
                                        method={method}
                                        setMethod={setMethod}
                                        imgStyle={{
                                            maxHeight: '30px',
                                        }}/>
                    </div>}

                    {paymentMethods.includes(PAYMENT_METHOD__CITADELE) && <div className={"col"}>
                        <RadioWithImage idPrefix={"appointment-payment-"}
                                        image={"/img/payment/citadele.svg"}
                                        value={PAYMENT_METHOD__CITADELE}
                                        method={method}
                                        setMethod={setMethod}
                                        imgStyle={{
                                            maxHeight: '25px',
                                        }}
                        />
                    </div>}

                    {paymentMethods.includes(PAYMENT_METHOD__STRIPE) && <div className={"col"}>
                        <RadioWithImage idPrefix={"appointment-payment-"}
                                        image={"/img/payment/cards3.svg"}
                                        value={PAYMENT_METHOD__STRIPE}
                                        thumbnailClassName={"px-2"}
                                        method={method}
                                        setMethod={setMethod}
                                        imgStyle={{width: 150, minWdth: 150}}/>
                    </div>}
                </div>

                <FormButtonsContainer>
                    <FormProceedButton isLoading={isLoading} onClick={onStart}>
                        <FormattedMessage id={'pages.booking-payment.button-proceed'}/>
                    </FormProceedButton>

                    <FormBackButton onClick={onCancel}>
                        <FormattedMessage id={'pages.booking-payment.button-cancel'}/>
                    </FormBackButton>
                </FormButtonsContainer>
            </>
            }
        </>}
    </>
}

const mapStateToProps = (state) => {
    return {
        patientOngoingReservation: state.patient.ongoingReservation
    }
};

const mapDispatchToProps = {fetchOngoingReservations, clearOngoingReservations};

export let PatientBookingPaymentPageLvWrapper = connect(mapStateToProps, mapDispatchToProps)((props) => {
    return <>
        <div className="row pt-5 pb-7">
            <div className={"offset-md-2 col-md-8"}>
                <PatientBookingPaymentPage {...props}/>
            </div>
        </div>
    </>
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientBookingPaymentPage);
