import React from "react";
import Loading from "@commons/ui/component/Loading";

let LoadingPage = () => {
    return <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
            <Loading/>
        </div>
    </div>
}

export default LoadingPage