export let OPENED_STEP_1 = 'so-appointment-request/opened-step-1';
export let openedStep1 = () => ({
    type: OPENED_STEP_1
})

let initialState = {
    hasOpenedStep1: false
}


export let soAppointmentRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPENED_STEP_1:
            return {
                ...state,
                hasOpenedStep1: true
            }
        default:
            return state
    }
}
