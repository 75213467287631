import React, {createRef, useState} from 'react';
import {tryLogout} from "@commons/domain/redux/actions";
import {connect} from "react-redux";
import {isLoggedIn, isLoggedInAsAdmin, isLoggedInAsDoctor, isLoggedInAsSoPatient} from "@commons/infra/helpers";
import {Offcanvas} from "react-bootstrap";
import {useLogoutHandler, useStickyHeader} from "@commons/ui/hooks";
import {FormattedMessage, useIntl} from "react-intl";
import Logo from "@commons/ui/component/Logo";
import Navigation from "@commons/ui/component/Navigation";
import {PatientLinks, PublicLinks} from "../../links";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {CommonPublicLinks} from "@commons/infra/links";

let mobileBreakpoint = 'sm';
let patientLinks = [];

const PublicHeader = function ({
                                   shadow = true,
                                   className = '',
                                   bgClassName = 'bg-white',
                                   variant = 'light',
                                   patientProfile,
                                   sticky = true,
                                   patientOngoingReservation
                               }) {
    let {formatMessage} = useIntl()
    let [offCanvasOpened, setOffCanvasOpened] = useState(false);
    let toggleOffCanvas = () => setOffCanvasOpened(!offCanvasOpened);
    let closeOffCanvas = () => setOffCanvasOpened(false);
    let logoutHandler = useLogoutHandler()
    let offcanvasContainerRef = createRef();

    useStickyHeader();

    const doLogout = (e) => {
        e.preventDefault();
        logoutHandler();
    };

    return <>
        <header
            className={`header navbar navbar-expand-lg navbar-${variant} ${sticky ? 'navbar-sticky sticky-top' : ''} ${shadow && 'navbar-box-shadow'} ${className} ${bgClassName}`}>
            <div className="container">
                {/* Burger */}
                <button className="navbar-toggler me-3"
                        type="button"
                        onClick={toggleOffCanvas}>
                    <span className="navbar-toggler-icon"/>
                </button>

                {/* Logo */}
                <LocalizedLink
                    className={`navbar-brand ms-lg-0 flex-grow-1 flex-${mobileBreakpoint}-grow-0`}
                    to={isLoggedInAsSoPatient() ? PatientLinks.dashboard() : '/'}>

                    <Logo/>
                </LocalizedLink>

                {/* Offcanvas */}
                <div className={"navbar"} ref={offcanvasContainerRef}/>

                {/* Toolbar */}
                <div className="d-flex align-items-center order-lg-3 ms-lg-auto">

                    {!isLoggedIn() && <>
                        <LocalizedLink
                            className={`nav-link ${variant === 'dark' && 'text-light'} nav-link fs-sm text-nowrap p-2 fw-medium d-none d-md-block`}
                            to="/login">
                            <FormattedMessage id={"components.public-header.login"}/>
                        </LocalizedLink>
                        {window.CONFIG.FEATURES.SO_REQUEST_APPOINTMENT && <LocalizedLink
                            className={`nav-link ${variant === 'dark' && 'text-light'} nav-link fs-sm text-nowrap fw-bold ms-3`}
                            to={PublicLinks.bookAnAppointment.calendar()}
                            style={{opacity: 1}}>
                            <div className={"btn btn-primary btn-sm show-on-stuck"}>
                                <FormattedMessage id={"so_header.appointment-request"}/>
                            </div>
                        </LocalizedLink>}
                    </>}

                    {isLoggedInAsSoPatient() && <>
                        <div className="dropdown d-none d-lg-block">
                            <a className="nav-link d-flex align-items-center p-0" href="/#"
                               onClick={e => e.preventDefault()}>
                                <div className="ps-2">
                                    <div className="fs-xs lh-1 opacity-60">
                                        <FormattedMessage id={"so_header.hello"}/>
                                    </div>
                                    <div
                                        className="fs-sm dropdown-toggle sensitive-data hw-not-allow">{patientProfile.name}</div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                {patientLinks.map(pl => <LocalizedLink to={pl.link} className="dropdown-item">
                                    <i className={`${pl.icon} fs-lg opacity-70 me-2`}/>
                                    <FormattedMessage id={pl.messageId}/>
                                </LocalizedLink>)}
                                {patientLinks.length > 0 && <div className="dropdown-divider"/>}
                                <LocalizedLink className="dropdown-item" to={PatientLinks.profile.info()}>
                                    <i className="ai-dashboard fs-lg opacity-70 me-2"/>
                                    <FormattedMessage id={"components.public-header.profile"}/>
                                </LocalizedLink>
                                <div className="dropdown-divider"/>
                                <a className="dropdown-item" href={"/#"}
                                   onClick={(e) => doLogout(e)}>
                                    <i className="ai-logout fs-lg opacity-70 me-2"/>
                                    <FormattedMessage id={"components.public-header.logout"}/>
                                </a>
                            </div>
                        </div>
                    </>}

                    {(isLoggedInAsDoctor() || isLoggedInAsAdmin()) && <a href='/#'
                                                                         onClick={(e) => doLogout(e)}
                                                                         className={`nav-link ${variant === 'dark' && 'text-light'} fs-sm text-nowrap d-flex align-items-center ms-2 p-2 me-lg-0 fw-500`}>

                        <i className="ai-logout fs-xl me-1"/>
                        <FormattedMessage id={"components.public-header.logout"}/>
                    </a>}
                </div>


                {/* Navigation */}
                <Offcanvas container={offcanvasContainerRef} renderStaticNode={true} className={"offcanvas-collapse"}
                           show={offCanvasOpened} responsive="lg" onHide={() => setOffCanvasOpened(false)}>
                    <div className="offcanvas-header">

                        <button type="button" className="btn btn-link text-dark btn-xl ps-0 text-decoration-none"
                                onClick={closeOffCanvas}>
                            <i className={"ai ai-cross-alt fs-lg"}/>
                        </button>
                    </div>

                    <div className="offcanvas-body pt-0">
                        {!isLoggedInAsSoPatient() ? <Navigation items={[

                            {
                                text: formatMessage({id: 'so_header.how-it-works'}),
                                to: PublicLinks.howItWorks()
                            },
                            {
                                text: formatMessage({id: 'components.public-header.specialists'}),
                                to: PublicLinks.doctors()
                            },
                            {
                                text: formatMessage({id: 'components.public-header.our-services'}),
                                items: [
                                    {
                                        text: formatMessage({id: 'so_header.second-opinions'}),
                                        to: PublicLinks.secondOpinions()
                                    },
                                    {
                                        text: formatMessage({id: 'so_header.prevention'}),
                                        to: PublicLinks.prevention()
                                    },
                                ]
                            },
                            {
                                text: formatMessage({id: 'components.public-header.your-health'}),
                                items: [
                                    {
                                        text: formatMessage({id: 'components.public-header.health-issues'}),
                                        to: CommonPublicLinks.healthIssues()
                                    },
                                    {
                                        text: formatMessage({id: 'so_components.public-header.areas-of-prevention'}),
                                        to: PublicLinks.preventionStrategies()
                                    },
                                ]
                            },
                            {
                                text: formatMessage({id: 'components.public-header.faq'}),
                                to: CommonPublicLinks.faq()
                            },
                            {
                                text: formatMessage({id: 'components.public-header.login'}),
                                to: PublicLinks.signIn(),
                                className: 'd-block d-md-none'
                            },
                        ]} onLinkClick={closeOffCanvas}/> : <Navigation items={[{
                            text: formatMessage({id: 'components.public-header.specialists'}),
                            to: PublicLinks.doctors()
                        }, {
                            text: formatMessage({id: 'components.public-header.my-bookings'}),
                            to: PatientLinks.booking.list()
                        }, {
                            text: formatMessage({id: 'so_header.cardiology'}),
                            to: PublicLinks.secondOpinions()
                        },
                            {text: formatMessage({id: 'components.public-header.faq'}), to: '/faq'}
                        ]} onLinkClick={closeOffCanvas}/>}
                    </div>
                </Offcanvas>
            </div>
        </header>
    </>;
};

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
        patientProfile: state.patient.profile,
        patientOngoingReservation: state.patient.ongoingReservation
    }
};

const mapDispatchToProps = {tryLogout};

export default connect(mapStateToProps, mapDispatchToProps)(PublicHeader)
