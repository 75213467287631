import React from "react";

let RadioWithImage = ({
                          idPrefix,
                          value,
                          image,
                          label,
                          showLabel = false,
                          thumbnailClassName,
                          method,
                          setMethod,
                          imgStyle = {},
                          text
                      }) => {
    let id = `${idPrefix}-radio-${value}`;

    return <>
        <div style={{cursor: 'pointer'}}
             onClick={() => setMethod(value)}>
            {/*<input type="radio" className="btn-check" id="weight1" name="weight" value="140 g" checked/>*/}
            <div
                className={`d-flex align-items-center justify-content-center shadow rounded-2 bg-white px-4 ${method === value ? "border-primary border-opacity-25 border-2" : "border-white border-2"}`}
                style={{height: 70, borderStyle: "solid"}}>
                {showLabel && <div className={"ms-3 text-muted mb-2 pb-2"}>{label}</div>}

                {!text && <img
                    className={" " + thumbnailClassName}
                    alt={label}
                    style={imgStyle}
                    src={image}
                />}

                {text && <div className={"fw-medium fs-sm d-flex align-items-center"}>
                    <i className={"ai ai-user-check fw-medium fs-xl me-2"}/>
                    <div style={{lineHeight: "initial"}} className={"fw-medium"}>{text}</div>
                </div>}
            </div>
        </div>
    </>
}

export default RadioWithImage