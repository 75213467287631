import {AUTHORITY_SO_PATIENT} from "@commons/infra/consts";
import {PatientLinks} from "../links";

export let postLoginRedirect = (authorities, customRedirectPage) => {
    if (customRedirectPage) {
        return {
            to: customRedirectPage, external: true
        }
    }
    if (authorities.includes(AUTHORITY_SO_PATIENT)) {
        return {to: PatientLinks.dashboard(), external: false}
    }

    return undefined
}