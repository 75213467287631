import React, {useEffect, useState} from "react";
import Select from "react-select";
import {DoctorApi} from "../../api/public/DoctorApi";
import {useIntl} from "react-intl";
import {useSpecialityFormatter} from "@commons/infra/helpers";

export let ACTION__FILTERS_UPDATED = 'FILTERS_UPDATED'

let DoctorsFilter = ({dispatcher, defaultSpeciality}) => {
    let {formatMessage} = useIntl()
    let specialityFormatter = useSpecialityFormatter();

    let [availableSpecialities, setAvailableSpecialities] = useState()
    let [speciality, setSpeciality] = useState(defaultSpeciality)
    let [search, setSearch] = useState()
    let [search$, setSearch$] = useState(search)

    useEffect(() => {
        DoctorApi.getDoctorSpecialities()
            .then(r => r.data)
            .then(d => setAvailableSpecialities(d.specialities.map(speciality => ({
                "value": speciality,
                "label": specialityFormatter.formatSpeciality(speciality),
            }))))
    }, [specialityFormatter])

    useEffect(() => {
        // if (!search) return;

        let timeOutId = setTimeout(() => {
            if (search$ === search) return;

            setSearch$(search)
        }, 300);

        return () => clearTimeout(timeOutId);
    }, [search, search$, dispatcher, setSearch$])

    // Filters updated
    useEffect(() => {
        dispatcher({type: ACTION__FILTERS_UPDATED, search: search$, speciality})
    }, [dispatcher, search$, speciality])

    return <div className={"container px-0"}>
        <div className={"row"}>
            <div className={"col-lg-4 col-md-5"}>
                <input className="form-control prepended-form-control"
                       type="text"
                       placeholder={formatMessage({id: 'components.doctors-filter.name-placeholder'})}
                       value={search}
                       onChange={e => setSearch(e.target.value)}/>
            </div>

            <div className={"col-lg-4 offset-lg-4 offset-md-2 col-md-5 mt-2 mt-md-0"}>
                <Select
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 20
                        }),
                    }}
                    isClearable={true}
                    placeholder={formatMessage({id: 'components.doctors-filter.specialities-placeholder'})}
                    onChange={item => {
                        if (item) {
                            setSpeciality(item.value)
                        } else {
                            setSpeciality(null)
                        }
                    }}
                    options={availableSpecialities}
                    value={availableSpecialities && availableSpecialities.filter(i => i.value === speciality)}
                    className={"custom-react-select"}
                />
            </div>
        </div>
    </div>
}

export default DoctorsFilter