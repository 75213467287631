import {alphabetSort} from "@commons/infra/helpers";

export let HEALTH_ISSUE__CORONARY_ARTERY_DISEASE = "coronary-artery-disease";
export let HEALTH_ISSUE__HYPERTENSION = "hypertension";
export let HEALTH_ISSUE__INCREASED_CHOLESTEROL = "increased-cholesterol";
export let HEALTH_ISSUE__ARRHYTHMIAS = "arrhythmias";

export let activeHealthIssues = [
    {key: HEALTH_ISSUE__CORONARY_ARTERY_DISEASE},
    {key: HEALTH_ISSUE__HYPERTENSION},
    {key: HEALTH_ISSUE__INCREASED_CHOLESTEROL},
    {key: HEALTH_ISSUE__ARRHYTHMIAS},
    {key: "atherosclerosis"},
    {key: "heart-failure"},
    {key: "congenital-heart-defects"},
    {key: "valvular-heart-diseases"},
    {key: "Aortic-Aneurysm"},
    {key: "Arrhythmogenic-Right-Ventricular-Dysplasia"},
    {key: "Atrial-Fibrillation"},
    {key: "Atrial-Flutter"},
    {key: "Atrial-Septal-Defect"},
    {key: "Brugada-Syndrome"},
    {key: "Cardiomyopathy"},
    {key: "Cor-Pulmonale"},
    {key: "Coronary-Artery-Spasm"},
    {key: "Coronary-Microvascular-Disease"},
    {key: "Dilated-Cardiomyopathy"},
    {key: "Ebsteins-Anomaly"},
    {key: "Endocarditis"},
    {key: "Heart-Valve-Stenosis"},
    {key: "Hemochromatosis-Related-Cardiomyopathy"},
    {key: "Hypertrophic-Cardiomyopathy"},
    {key: "Hypertrophic-Obstructive-Cardiomyopathy"},
    {key: "Inflammatory-Heart-Disease"},
    {key: "Long-QT-Syndrome"},
    {key: "Marfan-Syndrome"},
    {key: "Mitral-Valve-Prolapse"},
    {key: "Mitral-Valve-Stenosis"},
    {key: "Myocardial-Infarction"},
    {key: "Myocarditis"},
    {key: "Pericardial-Effusion"},
    {key: "Pericarditis"},
    {key: "Peripheral-Artery-Disease"},
    {key: "Peripheral-Venous-Disease"},
    {key: "Pulmonary-Embolism"},
    {key: "Pulmonary-Hypertension"},
    {key: "Pulmonary-Valve-Stenosis"},
    {key: "Restrictive-Cardiomyopathy"},
    {key: "Rheumatic-Heart-Disease"},
    {key: "Supraventricular-Tachycardia"},
    {key: "Takotsubo-Cardiomyopathy"},
    {key: "Ventricular-Septal-Defect"},
    {key: "Wolff-Parkinson-White-Syndrome"},
].sort((a, b) => alphabetSort(a.key, b.key))

export let activeHealthIssuesKeys = activeHealthIssues.map(i => i.key)